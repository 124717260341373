
import { Component, Mixins } from 'vue-property-decorator';
import mixinPassreset from '@/mixins/mixinPassreset';
import CommonProgress from "@/components/common/Progress.vue";
import PassresetMain from "@/components/passreset/Main.vue";

@Component({
  components: {
    CommonProgress,
    PassresetMain,
  }
})
export default class Passreset extends Mixins(mixinPassreset) {
}

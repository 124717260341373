import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins'
import {dataDescript, makeMixin, makeUtilMixin, validateDescript} from '@/mixins/Descriptor';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';

const utilDescriptor = {
  manager: vuex_manager.passreset_manager,
  data: [],
}
const globalDescriptor = {
  name: 'global',
  vuex: vuex_data.passreset_global,
  is_single: true,
  fetch(self) {
    return { token: self.query.token };
  },
};


@Component
export default class mixinPassreset extends Mixins(makeMixin(globalDescriptor), makeUtilMixin(utilDescriptor), utilMixins) {
  get global() {
    return dataDescript(this, globalDescriptor);
  }
  get global_validate() {
    return validateDescript(this, globalDescriptor);
  }
}


import { Component, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPassreset from '@/mixins/mixinPassreset';
import CommonProgress from "@/components/common/Progress.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";
import CommonText from "@/components/common/Text.vue";
import LWizard from "@/components/l/Wizard.vue";

@Component({
  components: {
    CommonProgress,
    CommonBigbutton,
    CommonText,
    LWizard,
  }
})
export default class Main extends Mixins(mixinPassreset) {

  created() {
    if (this.global.code != 0) {
      console.log('illegal from passreset');
      util.gotoIllegal(8);
    } else {
      this.m.setTmpObjects({name: 'step', val: 0.1});
      this.m.setTmpObjects({name: 'pass', val: {
        pass: null,
        pass_confirm: null,
      }});
    }
  }

  get step_hash() {
    const result = {};
    Object.keys(this.steps).forEach(key => {
      result[this.steps[key].index] = key;
    });
    return result;
  }
  get step_key() {
    return this.step_hash[this.step];
  }
  get steps() {
    const steps = {};
    let index = 1;
    steps['initial'] = {index: 0.1, name: '', is_header: false, is_next: true, next_index: 'input', next: '再設定する'};
    steps['input'] = {index: index++, name: '入力', is_prev: false, is_next: true, next_index: 'finish', prev_index: '', next: '再設定する'};
    steps['finish'] = {index: index++, name: '完了', is_header: true, is_prev: false, is_next: false, next_index: '', prev_index: ''};
    return steps;
  }

  async clickNext() {
    if (this.step_key == 'initial') {
      this.step = this.steps[this.steps['initial'].next_index].index;
    } else if (this.step_key == 'input') {
      this.m.create({name: 'reset_password', args: {pass: this.m.tmp_objects['pass'], token: this.query.token}});
    }
  }

  get tmp_pass() {
    return this.m.tmp_objects['pass'];
  }
  set tmp_pass(val) {
    this.m.setTmpObjects({name: 'pass', val});
  }
  get step() {
    return this.m.tmp_objects['step'];
  }
  set step(val) {
    this.m.setTmpObjects({name: 'step', val});
  }
}
